import React from 'react'
import Projectsinnercomp from './Projectsinner'
import { Divider } from '@mui/material';
// import project from '../project.jpeg';
import activity from '../activity.avif';
import movie from '../movie_review.avif';
import contact from '../contactmanager.avif';
import traceroute from '../traceroute.png';
import iot from '../iot.png';
import TechStackused from './TechStackused'; // Import the TechStack component

const Projects = () => {
  const isNormalDirection = true;
  const normalJustify = 'justify-end';
  const description1='Web that fully tracks user activities using React.js. Club members of any college club can track their daily activity. It also helps in connecting all clubs of the college and creates an easy communication way which fastens the work by 75%.';
  const description2='Created the backend architecture of a Contact Manager app using Node.js, streamlining contact and interaction management. Integrated functionality to efficiently track and manage interactions, boosting user engagement by 40%.'
  const description3='Developed a movie review platform using React.js, enabling users to share and access reviews; boosted user engagement by 40% and increased monthly active users by 25%'
  const description4=' Designed and implemented an intuitive network traceroute visualization tool, resulting in a 30% reduction in troubleshooting time for network engineers';
  const description5='Leveraged Hyperledger Fabric to enhance the security, integrity, and privacy of data generated by IoT devices. Implemented a permissioned blockchain framework to ensure authenticated access, data immutability, and transparent audit trails.';
 
  
  return (
    <div className='projects flex flex-col justify-center mt-4 h-fit mx-4 sm:mx-8 md:mx-14 min-h-[30rem] px-4 sm:px-8 md:px-16 lg:px-32 font-sans'>
      <div className='text-start flex items-center content-center font-bold font-serif text-slate-300 text-xl sm:text-2xl mb-5 mt-3'>
        <p className='w-24 sm:w-32'>Projects</p>
        <p className='w-full'><Divider className="mt-4 bg-white" /></p>
      </div>
      <Projectsinnercomp shift={{ dis: 'flex-col lg:flex-row-reverse', shifting: !isNormalDirection, end: 'justify-start', disend: normalJustify, dism: 'lg:-mr-10', pro:activity, description:description1, name:'Activity Tracker', tech:<TechStackused techStack={['React.js','Mui','Css','Tailwind']} /> }}/>
      <Projectsinnercomp shift={{ dis: 'flex-col lg:flex-row', shifting: isNormalDirection, end: normalJustify, disend: 'justify-start', dism: 'lg:-ml-10', pro:movie, description:description3, name:'Movie Review', tech:<TechStackused techStack={['React.js','Mui','Css','Tailwind']} /> }} />
      <Projectsinnercomp shift={{ dis: 'flex-col lg:flex-row-reverse', shifting: !isNormalDirection, end: 'justify-start', disend: normalJustify, dism: 'lg:-mr-10', pro:contact, description:description2, name:'Contact Manager', tech:<TechStackused techStack={['Node.js','Express.js']} />}}/>
      <Projectsinnercomp shift={{ dis: 'flex-col lg:flex-row', shifting: isNormalDirection, end: normalJustify, disend: 'justify-start', dism: 'lg:-ml-10', pro:traceroute, description:description4, name:'Traceroute', tech:<TechStackused techStack={['React.js','Css','Tailwind','Traceroute','D3.js']} />}} />
      <Projectsinnercomp shift={{ dis: 'flex-col lg:flex-row-reverse', shifting: !isNormalDirection, end: 'justify-start', disend: normalJustify, dism: 'lg:-mr-10', pro:iot, description:description5, name:'Iot-Security', tech:<TechStackused techStack={['JavaScript','BlockChain']} />}}/>
    </div>
  )
}

export default Projects